import React, { useRef, useState } from 'react';
import {
  categoryIds,
  formatMoney,
  Box,
  productSkuIds,
  useIdentifyBox,
  useIdentifyRecording,
  T_Product,
  orderStoreApi,
  useOrderStore,
} from '@sky-tv-group/shared';
import { Bin } from './RunningTotalIcons';
import { render } from '@testing-library/react';
import { BlockLoader, Loader } from '../loader';
interface RunningTotalBoxProps {
  box: Box;
  removable?: boolean;
  crossout?: boolean;
  upgradedTo?: Box;
  removeBox: (box: Box) => void;
  newUpgradeUi: boolean;
  showNew?: boolean;
  swapdevices?:(params:any)=>void
}

const RunningTotalBox = ({
  box,
  removable = true,
  crossout = false,
  upgradedTo,
  removeBox,
  newUpgradeUi,
  showNew,
  swapdevices
}: RunningTotalBoxProps) => {
  const [removeDisabled, setRemoveDisabled] = useState(false);
  const { getRecording } = useIdentifyRecording();
  const { formatBoxName, getBox } = useIdentifyBox();

  let product: T_Product | undefined = undefined;
  let recording: T_Product | undefined = undefined;

  if (box.boxType === 'NEW' || box.boxType === 'UPGRADE') {
    product = box.products.find(p => p.categoryId === categoryIds.box);
    recording = box.products.find(p => p.categoryId === categoryIds.recording);
  } else {
    product = getBox(box.occurence);
    recording = getRecording(box?.occurence);
  }

  async function removeProduct(removedBox: Box) {
    setRemoveDisabled(true);
    await removeBox(removedBox);
    setRemoveDisabled(false);
  }
 const [loading , setLoading] = useState(false);


  let productsMonthly = box?.products.filter(p => p.categoryId !== categoryIds.oneOffFee);
  let renderedProducts = box?.products
    .filter(p => {
      // If it's a box one off purchase fee, show as sub product
      if (
        p.sku === productSkuIds.arrowBoxOneOff.primary ||
        p.sku === productSkuIds.skyPodOneOffFee.primary ||
        p.sku === productSkuIds.arrowUpfrontBoxFee.primary
      ) {
        return true;
      }

      return p.categoryId !== categoryIds.oneOffFee;
    })
    .filter(
      p =>
        p.price0 !== 0 ||
        // show 0 price recordings ex. upfront recordings except for vod
        (p.categoryId === categoryIds.recording && p.sku !== productSkuIds.noRecording.primary)
    );

  let anyProductsMonthlyHasOffer = productsMonthly.find(p => p.price0 !== p.priceIncTax);

  let total = 0;
  let totalWithoutOffers = 0;

  productsMonthly.forEach(p => {
    total += p.priceIncTax;
    totalWithoutOffers += p.price0;
  });

  let addedProducts = upgradedTo?.products.filter(p => !box?.products.find(bp => bp.id === p.id));

  addedProducts?.forEach(p => {
    // do not add the one-off charges to box total
    if (
      p.sku === productSkuIds.arrowBoxOneOff.primary ||
      p.sku === productSkuIds.skyPodOneOffFee.primary ||
      p.sku === productSkuIds.arrowUpfrontBoxFee.primary
    ) {
      return;
    }

    total += p.priceIncTax;
  });
  const {order} = useOrderStore();
  let isBunlde=order?.orderProducts.find(p=>p.product.sku===productSkuIds.skyBestOfferBundle.primary)
  return (
    <div className=" text-navy pb-2 ">
      <div className="flex">
        {removable && !removeDisabled && (
          <span
            className="icon-wrapper mr-3 sky-text-pink"
            style={{ width: '15px', height: '15px', display: 'inline-block', cursor: 'pointer' }}>
            <span onClick={() => removeProduct(box)}>
              <Bin />
            </span>
          </span>
        )}
        <div className={`flex-1 ${newUpgradeUi && crossout ? 'upgrade-bg p-2' : ''}`}>
          <p
            className={` items-center justify-between pb-1 ${crossout ? 'line-through text-red' : ''} ${
              renderedProducts.length === 0 ? 'flex' : ''
            }
            ${!showNew ? 'flex flex-row text-13px sky-h7-reg' : 'sky-h6-reg'} `}
            data-testid={`running-total-product-${box?.id ?? box?.occurence}`}>
            <span className="flex items-center">
              {crossout && showNew
                ? `${formatBoxName(product, recording)} (Smartcard: ${box?.occurence?.serialNumber}) `
                : `${formatBoxName(product, recording)}`}
              {box.occurence?.nickName ? (
                <span className="flex-1 text-xs truncate">&nbsp;({box.occurence?.nickName})&nbsp;</span>
              ) : (
                ''
              )}
            </span>
            {!showNew && (
              <span>
                {anyProductsMonthlyHasOffer && !crossout && (
                  <span className="line-through text-red pr-1">{formatMoney(totalWithoutOffers)}</span>
                )}
                {formatMoney(total)}/mth
              </span>
            )}
            {showNew && renderedProducts.length === 0 && !upgradedTo && (
              <span>
                {anyProductsMonthlyHasOffer && !crossout && (
                  <span className="line-through text-red pr-1">{formatMoney(totalWithoutOffers)}</span>
                )}
                {formatMoney(total)}/mth
              </span>
            )}
          </p>
          {showNew && (
            <div className={`flex flex-col md:flex-row justify-start ${newUpgradeUi && crossout ? '' : 'md:gap-6'}`}>
              {!crossout && (
                <div>
                  {formatBoxName(product, recording) === 'New Sky Box' ? (
                    <img
                      src="https://static.sky.co.nz/sky/box/arrow-box-16-9.png"
                      alt="new sky box"
                      className="w-28 h-16 md:w-24 md:h-14"
                    />
                  ) : formatBoxName(product, recording) === 'Sky Pod' ? (
                    <img
                      src="https://static.sky.co.nz/sky/box/sky_pod_without_remote.png"
                      alt="sky Pod"
                      className=":w-28 h-16 md:w-24 md:h-14"
                    />
                  ) : formatBoxName(product, recording) === 'Sky Box' ? (
                    <img
                      src="https://static.sky.co.nz/sky/box/sky-box-16-9.png"
                      alt="sky box"
                      className="w-28 h-16 md:w-24 md:h-14"
                    />
                  ) : null}
                </div>
              )}
              <div className="flex flex-col justify-center w-full">
                {renderedProducts?.map((p, i) => {
                  const isABoxOneOffFee =
                    p.sku === productSkuIds.arrowBoxOneOff.primary ||
                    p.sku === productSkuIds.skyPodOneOffFee.primary ||
                    p.sku === productSkuIds.arrowUpfrontBoxFee.primary;

                  let hasOffer = !crossout && p.price0 !== p.priceIncTax;
                  if (!isABoxOneOffFee)
                    return (
                      <p
                        key={`${p.id}_${i}`}
                        className={`sky-h6-reg flex items-center justify-between py-1
                    ${crossout ? 'line-through text-red' : 'text-navy'}
                    `}
                        data-testid={`running-total-product-${p.id}`}>
                        <span>{p.name}</span>
                        {!isABoxOneOffFee && (
                          <span className="pl-2">
                            {hasOffer && <span className="line-through text-red pr-1">{formatMoney(p.price0)}</span>}
                            {formatMoney(p.priceIncTax ?? p.price0)}/mth
                          </span>
                        )}
                      </p>
                    );
                })}
                {upgradedTo &&
                  addedProducts &&
                  addedProducts.map(p => {
                    const hasOffer = !crossout && p.price0 !== p.priceIncTax;
                    const isABoxOneOffFee =
                      p.sku === productSkuIds.arrowBoxOneOff.primary ||
                      p.sku === productSkuIds.skyPodOneOffFee.primary ||
                      p.sku === productSkuIds.arrowUpfrontBoxFee.primary;

                    return (
                      <div className="flex flex-row items-center w-full">
                        <span
                          className="icon-wrapper mr-1 sky-text-pink"
                          style={{ width: '15px', height: '15px', display: 'inline-block', cursor: 'pointer' }}>
                          <span onClick={() => removeProduct(upgradedTo!)}>
                            <Bin />
                          </span>
                        </span>
                        <p
                          className={`w-full flex-1 text-13px flex items-center justify-between  py-1 ${
                            crossout ? 'line-through text-red' : 'text-gray-darker'
                          }`}
                          data-testid={`running-total-product-${p.id}`}>
                          {p.name}
                          {isABoxOneOffFee && (
                            <span className="pl-2">
                              {hasOffer && <span className="line-through text-red pr-2">{formatMoney(p.price0)}</span>}
                              {formatMoney(p.priceIncTax ?? p.price0)}
                            </span>
                          )}
                          {!isABoxOneOffFee && (
                            <span className="pl-2">
                              {hasOffer && <span className="line-through text-red pr-1">{formatMoney(p.price0)}</span>}
                              {formatMoney(p.priceIncTax ?? p.price0)}/mth
                            </span>
                          )}
                        </p>
                      </div>
                    );
                  })}
              </div>
            </div>
          )}

          {!showNew &&
            renderedProducts?.map((p, i) => {
              let hasOffer = !crossout && p.price0 !== p.priceIncTax;

              const isABoxOneOffFee =
                p.sku === productSkuIds.arrowBoxOneOff.primary ||
                p.sku === productSkuIds.skyPodOneOffFee.primary ||
                p.sku === productSkuIds.arrowUpfrontBoxFee.primary;

              return (
                <p
                  key={`${p.id}_${i}`}
                  style={{ fontSize: 10 }}
                  className={` text-13px flex items-center justify-between py-1 text-gray-darker
                ${!removable ? ' pl-6' : ''}
                ${crossout ? 'line-through text-red' : ''}
                ${!isABoxOneOffFee ? 'justify-between' : ''}
                `}
                  data-testid={`running-total-product-${p.id}`}>
                  {p.name}
                  {isABoxOneOffFee && (
                    <span className="pl-2">
                      {hasOffer && <span className="line-through text-red pr-2">{formatMoney(p.price0)}</span>}
                      {formatMoney(p.priceIncTax ?? p.price0)}
                    </span>
                  )}
                  {!isABoxOneOffFee && (
                    <span className="pl-2">
                      {hasOffer && <span className="line-through text-red pr-1">{formatMoney(p.price0)}</span>}
                      {formatMoney(p.priceIncTax ?? p.price0)}/mth
                    </span>
                  )}
                </p>
              );
            })}
            {box.primary && sessionStorage.getItem('isBundle')==='true' && window.location.pathname==='/build-my-plan/checkout' && <div className='w-full flex justify-end'>
              {loading  ?  <><div className='w-20 h-10 relative'><Loader bgOpacity={false} ></Loader></div> </>: <p className={`underline text-blue-pure font-bold cursor-pointer ${loading ? 'hidden':'block'}`}  onClick={async (e)=>{
                e.preventDefault();
                setLoading(true)

                setTimeout(async()=>{    if (swapdevices){
                  await swapdevices({selected:box.serviceCode==='616'?"624":"616",
                  oneoff:true,
                  recording: isBunlde? box.serviceCode==='616'?true:false :false,
                  primary:true,
                  editing:box
                })
              setLoading(false)}},1000)
               // ref.current!.style.display="block"
              //},
               }}>{`Switch to ${box.serviceCode==='616' ? 'new Sky Box' :'Sky Pod'}` }</p>}
            </div>}
        </div>
      </div>
    </div>
  );
};

export { RunningTotalBox };

export interface BasketItem {
  id: number;
  finalPriceExTax: number;
  finalPriceIncTax: number;
  product: T_Product;
  quantity: number;
  productId: number;
}
export interface T_OrderProduct {
  productId: number;
  quantity: number;
  product: T_Product;
  price: number;
  promotionTitle: string;
  categoryName: string;
  categoryId: number;
  rating: number;
  opts: T_Options[] | null;
  accessories?: number[];
  depends?: number[];
  downgrade?: boolean;
  inPromotion?: boolean;
  currentlySubscribed?: boolean;
  occurrenceNumber?: string;
  finalPriceIncTax?: number;
  broadbandPriceHold?: boolean;
  isPhantom?: boolean;
}
export interface T_OrderTotal {
  className: string;
  freeProductId: number;
  id: number;
  orderId: number;
  value: number;
  title: string;
  promotionId: number;
  promotions: {
    name: string;
  }[];
}
export interface T_Order {
  orderTotals: T_OrderTotal[];
  orderProducts: T_OrderProduct[];
  customerId: string;
}
export interface T_OrderWithCoupon {
  order: T_Order;
  coupon: T_Coupon;
}
export interface T_Coupon {
  name: string;
  id: number;
  couponCode: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  description: string;
}

export interface T_CouponRetrieval {
  r: T_Coupon[];
}

export interface T_KK_Promotion {
  custom3: string;
}

export interface T_PromotionRetrieval {
  r: T_KK_Promotion[];
}

export interface T_PromotionWithCoupon {
  custom3: string;
  coupon: T_Coupon;
}

export interface T_EligibilityOffer {
  originalPrice: number | undefined;
  discount: number | undefined;
  discountedPrice: number | undefined;
  coupon: T_Coupon | undefined;
}

export enum ServiceStatus {
  DataPendingOrder = -1,
  Active = 1,
  Disconnected = 2,
  PendingInstall = 3,
  PendingDisconnect = 4,
}
export interface T_Channel {
  logoThumbnail: string;
  sort: string;
  synopsis: string;
  url: string;
  number: string;
  logoInverted: string;
  promotionIntro: string;
  name: string;
  genre: string[];
  logo: string;
  hd: string;
  sku: string;
  order: string;
}
export interface T_Product {
  name: string;
  id: number;
  sku: string;
  url: string;
  idInCart: number;
  categoryId: number;
  categoryName: string;
  selected?: boolean;
  depends?: number[];
  accessories: number[];
  // Description to show when want to buy product
  description: string;
  // Description to show when product owned
  comparison: string;
  priceIncTax: number;
  priceBilled?: number;
  quantityInCart: number;
  quantityBought: number;
  image: string;
  prodRelationTypeArray: number[];
  // URL.
  custom1: string;
  // Upsell offer code.
  custom2: string;
  // Additional ICOMS codes (e.g. for boxes).
  custom3: string | null;
  // Whether ACQUISITION or UPGRADE (e.g. for boxes).
  custom4: string;
  // Legacy - things we no longer sell
  custom5: string;
  // Terms and conditions URL - free product
  custom6: string;
  // Upgradable boxes: service codes : separated by colon where everything before is too add and everything after is to remove e.g. 618,614,612:620,615
  custom7: string | null;
  // 'Short' description. e.g. for broadband products 'Up to 900Mbps'
  custom8: string | null;
  custom9: string | null;
  custom10: string | null;
  // Original price
  price0: number;
  // Special price
  price1: number;
  price3: number;
  rating: number;
  promotionTitle: string;
  downgrade?: boolean;
  model?: string;
  inPromotion?: boolean;
  serviceStatus?: ServiceStatus;
  // Additional fields from LR.
  videoUrl?: string;
  horizontalVideoUrl?: string;
  logoImageUrl?: string;
  explorePopupCopy?: string;
  horizontalImageUrl?: string;
  contractDescription?: string;
  showExplorePopup?: boolean;
  linkLabel?: string;
  linkUrl?: string;
  iconImageUrl?: string[];
  iconImageUrlFromJSON?: T_Icon_URL[];
  channels: T_Channel[];
  popupCards?: T_Product_LR_Popup[];
  occurrenceNumber?: string;
  broadbandPriceHold?: boolean;
  isPhantom?: boolean;
}

export interface T_Icon_URL {
  logo_url: string;
}

export interface T_Product_LR_Popup {
  popupCardImageURL: string;
  popupCardTitle: string;
  popupCardSubtitle: string;
  popupCardDescription: string;
  popupCardIconURL: string;
}

export interface T_Product_LR {
  name: string;
  sku: string;
  videoUrl: string;
  mainImageUrl: string;
  logoImageUrl: string;
  description: string;
  explorePopupCopy?: string;
  contractDescription: string;
  price: string;
  showExplorePopup?: boolean;
  linkLabel: string;
  linkUrl: string;
  iconImageUrl: string[];
  popupCards: T_Product_LR_Popup[];
}

export interface T_Options {
  name: String;
  value: String;
}

export interface T_Subscription {
  code: string;
  name: string;
  product: T_Product;
}

export enum UserGroupType {
  UNLINKED_ACCOUNT = 'customerType-unlinked',

  /** Identity account not linked with ICOMS. */
  LINKED_ACCOUNT = 'customerType-linked',

  /** Sky customer, defined by not being a reseller nor retransmission customer. */
  SKY_CUSTOMER = 'customerType-skyCustomer',

  /** Identity account that falls into the 'type 0 - Vodafone Reseller' category. */
  RESELLER_CUSTOMER = 'customerType-resellerCustomer',

  /** Retransmission customer, defined by being linked to an account of type TCL. */
  RETRANSMISSION_CUSTOMER = 'customerType-retransmissionCustomer',

  /** Vodafone customer that will be migrated to Sky Customer. */
  SKY_VTV_CUSTOMER = 'customerType-vtv',

  /** Former account. */
  FORMER_CUSTOMER = 'customerType-former',

  /** Every new user to sky would be in NEVR state, until the install is completed (Work order completed).  */
  NEVER_CUSTOMER = 'customerType-never',

  SKY_BUSINESS = 'customerType-skyBusinessCustomer',
}

export interface T_Profile {
  firstName: string;
  lastName: string;
  displayName: string;
  profileId: string;
  email: string;
  status: string;
  accountNumber: string;
  parentalControlEnabled: boolean;
  parentalPin: string;
  parentalRatingLevel: string;
  blockAdultContent: boolean;
  receiveMarketingEmail: boolean;
  associated: boolean;
  type: string;
  groups: UserGroupType[];
  companyName?: string;
}

export interface T_ValidateAccount {
  dateOfBirth: string;
}

export interface T_VerifyAndLinkAccount {
  dateOfBirth: string;
  password: string;
  token: string;
  profileId: string;
}

export interface T_LinkAccount {
  dateOfBirth: string;
  identifier: string;
  profileId: string;
}

export interface T_Token {
  uid: string;
}

type CustomerType = 'ICOMS' | 'TCL';

export interface T_DesignatedAccount {
  type: CustomerType;
  number: string;
}

export interface T_Device {
  product: string;
  deviceId: string;
  profileId: string;
  alias: string;
  dateRegistered: string;
  enabled: boolean;
  description: string;
}

export type OccurrenceType = 'SKYBOX' | 'MYSKY+' | 'MYSKY' | 'B/B' | 'ARROW' | 'SKYPOD';

export interface T_Occurrence {
  accountNumber: string;
  serialNumber: string;
  occurrenceNumber: string;
  nickName: string;
  occurrenceType: OccurrenceType;
  portType: string;
  itemNumber: string;
  isRemoteRecordCapable: boolean;
  entitlements: {
    code: string;
    name: string;
  }[];
}

export interface T_Eligibility_Offers {
  offers: T_Offer[];
  subscriberKey: string;
}

export interface T_Offer {
  campaign: string;
  discountServiceCode: string;
  eligibleFor: string;
  production: boolean;
  promotion: T_KK_Promotion | undefined;
  coupon: T_Coupon | undefined;
  priority: number;
  qualifies: boolean;
  campaignDescription: string;
  additionalPackages: string;
  offerPackages: string;
  applies: string;
  overrideCurrentOffer: boolean;
  eligibilityID: string;
  displayPromptOffer: boolean;
  recordType: string;
  validToDate: string;
}

export interface T_SKU {
  sku: string;
  inboundIcomsCodes: string[];
  provisioningCodes: string[];
  upgradePathSkus: string[];
  perOccurrenceCodes: string[];
  perNonDigiCodes: string[];
  accountCodes: string[];
  feeCodes: string[];
  removeCodes: string[];
  enableChannels: string[];
  singleOccurrenceCodes: string[];
  singleRemoveCodes: string[];
  trialCodes: string[];
  groupId: null | string;
  articleId: null | string;
  modified: number;
  digi: boolean;
  decoder: boolean;
  deprecated: boolean;
}

export interface T_Marketing_Product {
  sku: string;
  name: string;
  composite: boolean;
  allowMultipleQty: boolean;
  rankInCategory: number;
  contentType: string;
  sortWeight?: string;
  groupId: string;
  articleId: string;
  content?: string;
  hideTerms: boolean;
  modified: number;
  editProductURL?: string;
  imageUrl?: string;
  link?: string;
  description?: string;
  terms?: string;
  subheading?: string;
}

export interface T_Customer {
  accountNumber: string;
  houseNumber: string;
  externalAccountId: string;
  category: string;
  type: string;
  pin: string;
  secondaryLocatorCode: string;
  fusionRegion: string;
  serviceAddress: ServiceAddress;
  serviceContactEmail: string;
  Parties: Party[];
  paperLessBilling: boolean;
  tuiAddressCode: string;
  privacyList: Privacy[];
}

export interface Privacy {
  privacyCode: string;
  privacyCodeExpiration: string;
  privacyCodeEffective: string;
  cancelationFlag: string;
  notificationTypeCode: string;
}

export interface Party {
  id: string;
  types: Types;
}

interface Types {
  'MP:Primary'?: ApAuthorised;
  'BT:Billing'?: ApAuthorised;
  'AP:Authorised'?: ApAuthorised;
}

export interface ApAuthorised {
  personalDetails: PersonalDetails;
  contactDetails: ContactDetail[] | null;
  emailAddress?: [
    {
      emaiId: string;
      sequenceNumber: number;
      type: string;
    }
  ];
  contactAddress: ContactAddress;
}

interface ContactAddress {
  streetAddress: Address;
  freeFormAddress: FreeformAddress;
}

export interface Address {
  houseNumber: null | string;
  streetName: null | string;
  suburb: null | string;
  city: null | string;
  postCode: null | string;
}
export interface FreeformAddress {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
}
interface ContactDetail {
  sequenceNumber?: number;
  type: string;
  areaCode: string;
  number: string;
}

export interface PersonalDetails {
  title: string;
  firstName: string;
  lastName: string;
  dob?: string;
  language?: string;
}

export interface ServiceAddress {
  primaryAddress: Address;
}

export interface T_PPV_PURCHASED {
  titleCode: string;
  showingNumber: number;
  startTime: Date;
  titleDescription: string;
  videoRatingCode: string;
  amountCharged: number;
  eventStatus: number;
  eventStatusDescription: string;
  orderSource: number;
  smartCards: SmartCard[];
  transactionDate: Date;
}

export interface T_PPV {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  group: string;
  genre: string;
  parentalRatingLevel: number;
  rating: string;
  offerings: Offering[];
  mixedContent: boolean;
  booked?: boolean;
  bookedOfferings?: T_PPV_HISTORY[];
}

export interface T_PPV_HISTORY {
  titleCode: string;
  showingNumber: number;
  startTime: string;
  titleDescription: string;
  videoRatingCode: string;
  amountCharged: number;
  eventStatus: number;
  eventStatusDescription: string;
  orderSource: number;
  smartCards: Partial<SmartCard>[];
  transactionDate: string;
}

interface Offering {
  id: string;
  showings: Showing[];
  channel: number;
  time: Time;
  bookableTime: Time;
  price: number;
  titleCode: string;
  showingNumber: number;
  streamable: boolean;
  adultContent: boolean;
  serviceName: string;
}

interface Time {
  start: string;
  end: string;
}

interface Showing {
  time: Time;
  bookableTime: Time;
  duration: number;
  id: string;
  offeringId: string;
  price: number;
  channel: number;
  adultContent: boolean;
  replay: boolean;
}
export interface T_PPVPayload {
  accountNumber: string;
  pinNumber: string;
  purchaserId: string;
  showingNumber: number;
  titleCode: string;
  smartCard: string;
}

export interface T_MOP {
  mopdscrptn: string;
  mopacntnmbr: string;
  mthdofpaycode: string;
  mopasgnd: 'Y' | 'N';
}

export interface T_MOP_Payload {
  autoAssignment: number;
  bankDraftAccountType: string;
  createIfExists: string;
  creditCardNumber: string;
  externalMopMaintenanceInd: 'Y' | 'N';
  methodPayCode: number;
  mopAccountTitle: string;
  taskCode: string;
  sendEmail: boolean;
  firstName: string;
  emailID: string;
}

// billing interface
export interface T_Billing {
  accountNumber: string;
  houseNumber: string;
  lastStatementDate: string;
  statementDueDate: string;
  lastPaymentAmount: string;
  lastStatementBalance: null;
  currentTotalAmountDueCents: string;
  monthlyCharge: string;
  paperLessBilling: boolean;
  statementServiceCategories: StatementServiceCategory[];
  ledger: Ledger;
  services: Services;
  campaigns?: BillingCampaign[];
  channelLineupDefault: string;
  primaryAddress: boolean;
  pendingOrderCount: number;
  activationDate: string;
  status: number;
}

export interface BillingCampaign {
  beginDate: string;
  endDate: string;
  active: boolean;
  groupCode: string;
  promotionCode: string;
  promotionDescription: string;
  containedServices: Services;
  activeVTV?: boolean;
}
interface Ledger {
  delinquentAmount1To30: string;
  delinquentAmount31To60: string;
  delinquentAmount61To90: string;
  delinquentAmount91To120: string;
  delinquentAmount121To150: string;
  delinquentAmountPlus: string;
  pendingPayment?: string;
  unappliedPayment: string;
  unappliedAdjustment: string;
  writeOffAmount: string;
  totalArrears?: string;
  inArrears?: boolean;
}

export interface Services {
  CableServiceOccurrence: ServiceOccurrence[];
  DataServiceOccurrence: ServiceOccurrence[];
  TelephoneServiceOccurrence: ServiceOccurrence[];
  OTTServiceOccurrence?: ServiceOccurrence[];
}

export interface ServiceOccurrence {
  occurrenceNumber: number;
  status: number;
  occurrenceType: string;
  serviceDetails: ServiceDetail[];
  installDate: string;
  billWith: With;
  billUsageWith: With;
}

interface With {
  accountNumber: string;
  statementCode: string;
}

export interface ServiceDetail {
  serviceCode: string;
  rate: Rate;
  alaCarteRate: Rate;
  quantity: number;
  occurrenceDefinition: number;
  serviceStatus: number;
  installDate: string;
  startBillingDate: string;
  billThroughDate: null | string;
  lastChangeDate: string;
  billingFrequency: BillingFrequency;
  externalProductId: number;
  billingDescription: string;
  invoiceWith: With;
}

export interface Rate {
  type: Type;
  amount: string;
  afterTax: string;
}

export enum Type {
  Nzd = 'NZD',
}

export enum BillingFrequency {
  Monthly = 'Monthly',
}

interface StatementServiceCategory {
  Cable: Ledger;
  Data: Ledger;
  Telephone: Ledger;
}
// billing interface end

export interface T_BillingDoc {
  documentId: string;
  totalDue: string;
  invoiceDate: string;
  subscriptionPeriod: string;
}
export interface T_BillingOtherDoc {
  documentId: string;
  deliveryMethod: string;
  amount: string;
  letterDate: string;
}
export interface T_BillingPDF {
  pdfEncodedData: string;
}

export interface T_Category {
  id: number;
  name: string;
}

export interface T_BasketItem {
  id: number;
  finalPriceExTax: number;
  finalPriceIncTax: number;
  product: T_Product;
  quantity: number;
  productId: number;
  downgrade: boolean;
  inPromotion: boolean;
  currentlySubscribed: boolean;
  occurrenceNumber?: string;
  broadbandPriceHold?: boolean;
  isPhantom?: boolean;
}

export enum TagTypeEnum {
  STRING = 0,
  INT,
  MULTI_INT,
  DECIMAL,
}

export interface Tag {
  name: string;
  value: string | number;
  type: TagTypeEnum;
}
export enum RelationshipEnum {
  ALL = 0,
  ACCESSORY = 3,
  DEPENDENT = 4,
  BUNDLE = 5,
}
export interface T_Relationship {
  prodIdFrom: number;
  prodIdTo: number;
  productId: number;
  relationType: RelationshipEnum;
  quantity: number;
}

export interface T_PPV_Mobile {
  accountNumber: string;
  houseNumber: string;
  inArrears: boolean;
  isRestricted: boolean;
  smartCards: SmartCard[];
  ppvSmsNumber: string;
  pinNumber: string;
  active: boolean;
}

interface SmartCard {
  serial: string;
  portNumber: number;
  itemNumber: string;
  occurrenceNumber: number;
  installDate: Date;
  subscriberId: string;
  nickname: string;
  allowedPPV: boolean;
}

export interface T_EPG_Channel {
  logoThumbnail: string;
  sort: string;
  synopsis: string;
  url: string;
  number: string;
  logoInverted: string;
  promotionIntro: string;
  name: string;
  genre: string[];
  logo: string;
  hd: string;
  sku: string;
  order: string;
  events: T_EPG_Event[];
  epg: string;
}

export interface T_EPG_Event {
  id: string;
  title: string;
  synopsis: string;
  channelNumber: number;
  start: string;
  end: string;
  genres: string[];
  rating: string;
  seriesId: string;
  startAt: number;
  endAt: number;
  startFromInit: number;
  endFromInit: number;
  relatedEvents: T_EPG_Event[];
}

export interface T_EPG_Remote_Record {
  eventID: string;
  seriesId?: number;
  smartCardID: string;
}

export interface T_EPG_SearchResult {
  category: string;
  events: T_EPG_Event[];
}

export interface AddressLookup {
  id: string;
  label: string;
  tui?: string;
}

export interface T_AddressLookUpDetail {
  formatted_address: FormattedAddress;
  structured_address: StructuredAddress;
  references: ReferenceAddress;
}

export interface ReferenceAddress {
  id: string;
  dpid?: string;
  tui?: string;
}
export interface FormattedAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
}

export interface StructuredAddress {
  level_number: null;
  level_type: null;
  street_number: number;
  situation_number: number;
  suffix: string;
  unit: string;
  unit_type: string;
  street_name: string;
  road_type: string;
  road_abv: string;
  road_suffix: string;
  suburb: string;
  rural_delivery: string;
  town: string;
  postcode: string;
  box_number: string;
  box_lobby: string;
  box_type: string;
  region: string;
  country: string;
  is_primary: string;
}

// Order api types
// Return structure
export interface ResponseMessage {
  executionStepName: string;
  success: boolean;
  type: string;
  message: string;
}

export interface OrderNumber {
  type?: any;
  value?: any;
  techVisit: boolean;
  responseMessages?: any;
}

export interface OrderError {
  code: string;
  message: string;
  description: string;
  responseMessage?: any;
}

export interface OrderResponse {
  accountNumber: string;
  houseNumber: string;
  externalAccountId?: any;
  responseMessages: ResponseMessage[];
  orderNumbers: OrderNumber[];
  errors: OrderError[];
  initialOrderId: string;
  paymentUrl?: string;
}

// Body
export interface BillingAddress {
  dpid: string;
  street: string;
  suburb: string;
  city: string;
  postcode: string;
}

export interface PhoneNumberDetail {
  countryCode: string;
  areaCode: string;
  number: string;
}

export interface PhoneNumber {
  type: string;
  phoneNumber: PhoneNumberDetail;
}

export interface OrderServiceAddress {
  tuiid: string;
  street: string;
  suburb: string;
  city: string;
  postCode: string;
}

export interface OrderDeliveryAddress {
  tuiid: string;
  street: string;
  suburb: string;
  city: string;
  postCode: string;
}

export interface CustomerDetails {
  email: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  icomsPin: string;
  billingAddress?: BillingAddress;
  phoneNumbers: PhoneNumber[];
  serviceAddress: OrderServiceAddress;
  houseOwnership: string;
  billViaEmail: string;
  lengthOfStay?: LengthOfStay;
  relative?: Relative;
  relativeAddress?: RelativeAddress;
  authorisedPerson?: AuthorisedPerson;
  paymentMethod?: string;
  friendGetFriend?: string;
  friendGetFriendAccount?: string;
  marketingEmails?: boolean;
  directDebit?: {
    accountType: 'Cheque' | 'Savings';
    accountHolder: string;
    bankAccount: string;
  };
  creditCardPayment?: string;
}

export interface Campaign {
  id: string;
  description?: string;
}

export interface Action {
  add?: string[];
  remove?: string[];
}

export interface BroadbandService {
  id?: string;
  installDate?: string;
  installTime?: string;
  actions: BroadbandAction[];
  details: Detail[] | VoiceDetails[];
}

export interface BroadbandProduct {
  type: string;
  campaigns?: Campaign[];
  services: BroadbandService[];
}

export interface Detail {}

export interface DeliveryInfo {
  contactName: string;
  contactNumber: string;
  contactEmail: string;
  notes: string;
}

export interface DeliveryAddress {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  tui: string;
  deliveryInfo: DeliveryInfo;
}

export interface BroadbandAction {
  add?: string[] | any[];
  remove?: string[] | any[];
}

export interface Multiroom {
  action: string;
  quantity?: number;
}
export interface Service {
  id?: string;
  customerAction?: string;
  installDate?: string;
  installTime?: string;
  actions: Action[];
  details: Detail[];
  multiRoom?: Multiroom;
  deliveryDetails?: DeliveryAddress;
}

export interface Product {
  type: string;
  campaigns?: Campaign[];
  services: Service[];
}

export interface OrderDetails {
  products: Product[] | BroadbandProduct[];
  transfer?: boolean;
  eligibilityID?: string;
}

export interface RootOrder {
  accountNumber?: string;
  houseNumber?: string;
  externalAccountId?: string;
  language?: string;
  customerDetails: CustomerDetails;
  orderDetails: OrderDetails;
}

export interface LengthOfStay {
  years: string;
  months: string;
}

export interface Relative {
  firstName: string;
  lastName: string;
  relationship: string;
  phone: string;
}

export interface RelativeAddress {
  street: string;
  suburb: string;
}

export interface AuthorisedPerson {
  firstName: string;
  lastName: string;
  relationship: string;
  emailAddress: string;
  phoneNumbers: PhoneNumber[];
}

export interface T_Entitlement {
  code: string;
  name: string;
}

type ValueOf<T> = T[keyof T];

export const InstallationTimeCodes = {
  AM: '8am - 12pm',
  AM1: '8am - 9am',
  AM2: '9am - 12pm',
  PM: '12pm - 6pm',
  PM1: '12pm - 4pm',
  PM2: '4pm - 6pm',
  ALLDAY: '8am - 6pm',
} as const;

export const InstallationTimeCodeKeys = {
  AM: 'AM',
  AM1: 'AM1',
  AM2: 'AM2',
  PM: 'PM',
  PM1: 'PM1',
  PM2: 'PM2',
  ALLDAY: 'ALLDAY',
};

export const InstallationTimeCodesHalfDay = {
  AM: '8am - 12pm',
  PM: '12pm - 6pm',
} as const;

export const OwnershipFields = {
  Rent: 'Rent',
  Own: 'Own',
  Empty: '',
} as const;

export const SellingStatus = {
  Legacy: 'LEGACY',
  Upfront: 'UPFRONT',
} as const;

export const CouponTypeValue = {
  Acquisition: 'ACQUISITION',
  Upgrade: 'UPGRADE',
} as const;

export type InstallationTime = ValueOf<typeof InstallationTimeCodeKeys>;
export type Ownership = ValueOf<typeof OwnershipFields>;
export type NullableDate = null | Date;
export type NullableAddressLookup = null | AddressLookup;
export type CouponType = ValueOf<typeof CouponTypeValue>;

export interface Link {
  readonly title?: string;
  readonly link?: string;
  readonly newTab?: boolean;
  children?: Link[];
  parent?: Link;
}

export interface NavigationLinks {
  readonly logoUrl: string;
  readonly logoSrc: string;
  readonly TVGuideURL: string;
  readonly header: Link[];
  readonly side: Link[];
  readonly footer: Link[];
  readonly social: Link[];
  readonly corporate: Link[];
}

//get work orders types
export interface WorkOrder {
  workOrderNumber: number;
  status: string;
  checked: string;
  dateEntered: number;
  dateScheduled: number;
  dateCompleted: number;
  type: string;
  code: string;
  typeDescription: string;
  userEntered: string;
  dateLastChanged: number;
  userLastChanged: string;
  checkInStatusDescription: string;
  addressLine1: string;
  addressLine2: string;
  workOrderClassDescription: string;
  details: WorkOrderDetail[];
}

export interface WorkOrderDetail {
  category: string;
  occurrence: number;
  serviceCode: string;
  description: string;
  from: number;
  to: number;
  amount: string;
  csrId: string;
}

export interface WorkOrderStatus {
  workOrderNumber: string;
  status: string;
  checked: string;
  type: string;
  typeDescription: string;
  taskId: string;
  dateEntered: string;
  dateScheduled: string;
  dateCompleted: string;
  code: string;
  userEntered: string;
  dateLastChanged: string;
  userLastChanged: string;
  checkInStatusDescription: string;
  addressLine1: string;
  addressLine2: string;
  workOrderClassDescription: string;
  timeSlot: string;
  timeSlotDescription: string;
  stageCode: string;
  stageCodeDescription: string;
  originalScheduleDescription: string;
  officeOnlyFlag: string;
  details: any[];
}

export interface T_Notification {
  id: string;
  title: string;
  text: string;
  tcLink: string;
  tcLinkLabel: string;
  actionBtnLabel: string;
  actionBtnUrl: string;
  dismissBtnLabel: string;
  scope: string;
  order: number;
  specialHandler: string;
  pageTarget: string[];
  pageExclude: string[];
}

export interface LocationAddresses {
  addresses: LocationAddress[];
}

export interface LocationAddress {
  locationId: string;
  coreLogicTui: string | undefined;
  addressText: string;
  floor: string | undefined;
  buildingName: string | undefined;
  flatNumber: string | undefined;
  streetNumber: string;
  streetNumberSubDescription: string | undefined;
  streetName: string;
  streetType: string;
  streetDirection: string | undefined;
  suburb: string;
  city: string;
  postcode: string;
}

export interface RefundCreditForm {
  customer: RefundCreditFormCustomer;
}

export interface RefundCreditFormCustomer {
  accountNumber: string;
  accountName: string;
  contactNumber: string;
  emailAddress: string;
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  authorised: boolean;
}

export interface ServiceCodesModify {
  customerAction: string;
  addedItem: string[];
  removedItem: string[];
}

export enum BroadbandReady {
  Unknown,
  FibreOK,
  FibreFuture,
  FibreNever,
}

export enum FibreCompanies {
  Chorus = 'ChorusNGA',
  Enable = 'Enable',
  NorthPower = 'NorthPower',
  UFF = 'UFF',
}

export enum CouponTypes {
  Cable = 'CABLE',
  Broadband = 'BROADBAND',
  Voice = 'VOICE',
}

export enum TechLookupProductType {
  CABLE = 'Cable',
  DATA = 'Data',
  CABLE_AND_DATA = 'CableData',
}

export enum TechLookupOrderType {
  INSTALL = 'IN',
  UPGRADE = 'UP',
}

export enum TechLookupTechType {
  SKY = 'Sky',
  CHORUS = 'Chorus',
}

export interface TechnicianSlot {
  code: string;
  startTime: Date;
  endTime: Date;
  displayName: string;
}
export interface TechnicianDateSlots {
  date: Date;
  slots: TechnicianSlot[];
}

export interface PaymentPayload {
  txnType?: string;
  amount: string;
  successURL: string;
  failureURL: string;
}

type ToastType = 'success' | 'warning' | 'error' | 'offer';

export interface ToastProps {
  /*
   *The type of the toast
   */
  type: ToastType;
  /*
   *The title to show
   */
  title: string;
  /*
   *the message to show
   */
  message: string;
  /*
   *The action for the left button
   */
  action?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /*
   *The text for the left button, required if an action is provided
   */
  actionText?: string;
  /*
   *What to do when the toast is closed
   */
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /*
   *The text for the close butotn, required if an action is provided
   */
  closeText?: string;
}

export type YesNoOptionValues = 'Yes' | 'No' | '';

export const YesNoOptionCheckCodes = {
  SELECT_OPTION_BELOW: {
    value: '',
    text: 'Select an option',
  },
  YES: {
    value: 'Yes',
    text: 'Yes',
  },
  NO: {
    value: 'No',
    text: 'No',
  },
} as const;

export type YesNoOptionCheck = ValueOf<typeof YesNoOptionCheckCodes>;

export const DirectoryListingCodes = {
  AVAILABLE_TO_EMERGENCY_SERVICES: 'Confidential - Available to emergency services',
  UNAVAILABLE_TO_EMERGENCY_SERVICES: 'Confidential - Unavailable to emergency services',
  UNPUBLISHED: 'Unpublished',
  PUBLISHED: 'Published',
  NO_CHANGE_TO_EXISTING_DIRECTORY_LISTING: 'No change to existing Directory listing',
} as const;

export type DirectoryListingTypes = ValueOf<typeof DirectoryListingCodes>;

export type T_OrderRequest = Pick<
  T_BasketItem,
  | 'productId'
  | 'quantity'
  | 'finalPriceIncTax'
  | 'downgrade'
  | 'inPromotion'
  | 'currentlySubscribed'
  | 'occurrenceNumber'
  | 'broadbandPriceHold'
  | 'isPhantom'
>;

export interface T_Phone_Options_Payload {
  count: number;
  locationId: string;
}

// Please update as necessary
// https://take5.skytv.co.nz/pages/viewpage.action?spaceKey=1MS&title=Tracking+Schema+and+Response

export interface BroadbandDetails {
  coreProduct: string;
  coreProductKey: string;
  coreProductId: string;
  ratePlan: string;
  type: 'internet' | 'voice';
}

export interface T_Tracking {
  accountNumber: string;
  houseNumber: string;
  providerAccountId: string;
  dataProviderUserId: string;
  voiceProviderUserId: string;
  bbDetails: BroadbandDetails[];
}

export interface Addons {
  type: string;
  productId: string;
  ratePlan: string;
}
export interface LosingServiceProviderDetails {
  existingPhoneNumber: string;
  accountNumber: string;
  portIn: boolean;
  serviceProvider: string;
}

type VoiceAction = 'add' | 'remove';

export interface VoiceDetails {
  productId: string;
  broadbandProduct: string;
  newPhoneNumber?: string | undefined;
  losingServiceProviderDetails?: LosingServiceProviderDetails;
  addons: Addons[];
  // add voice to existing BB details
  providerAccountId?: string;
  dataProviderUserId?: string;
  voiceProviderUserId?: string;
  voiceProduct?: string;
  availableProductKey?: string;
  voiceAction?: VoiceAction;
}

export interface BroadbandBannerContent {
  title: string;
  description: string;
  price: string;
  other: string;
  desktopImageUrl: string;
  mobileImageUrl: string;
}

export interface BroadbandBanner {
  Banner: BroadbandBannerContent;
}

export type T_DiagnosticsTest = {
  diagnostics: {
    isOnline: boolean;
  };
};

export interface categoryGroups {
  groupName: string;
}

export interface article {
  title: string;
  summary: string;
  url: string;
  categoryGroups: categoryGroups[];
}

export interface articles {
  articles: article[];
}

export interface DateRange {
  exclude: Date[];
  latest: Date;
  earliest: Date;
}

export interface YourDetails {
  firstName: string;
  familyName: string;
  email: string;
  phoneAreaCode: string;
  phoneTelephoneNumber: string;
  dateOfBirth: NullableDate;
  emailConsent?: boolean;
}
export interface AddressData {
  selectedAddressId: string | undefined;
  selectedInstallationAddress: string | undefined;
}

export interface PropertyDetail {
  address: NullableAddressLookup;
  paperlessBilling: boolean;
  billingAddressSameAsInstallationAddress: boolean;
  billingAddress: NullableAddressLookup;
  serviceContactEmail: string;
  useDifferentDeliveryAddress: boolean;
  deliveryAddress: NullableAddressLookup;
}

export interface InstallationDetail {
  installationDate: NullableDate;
  installationTime: string;
  rentOrOwn: Ownership;
}
export interface PaymentInfo {
  isDD: boolean;
  ddAccountName?: string;
  ddAccountNumber?: string[];
  ddBankName?: string;
  ccAccountNumber?: string;
  ccHolderName?: string;
  ccExpire?: string;
  ccCVC?: string;
}
export interface AccountDetails {
  propertyDetail: PropertyDetail;
  installationDetail: InstallationDetail;
  yourDetail: YourDetails;
  paymentInfo: PaymentInfo;
  marketingEmails?: boolean;
}

export interface T_ProductSelector {
  header: {
    title: string;
    subtitle: string;
  };
  price: {
    name: string;
    price: string;
    nonStarterPrice: string;
    term: string;
    discount?: string;
  };
  features: {
    main: string;
    sec: string;
    extra?: string;
  }[];
  bundle: boolean;
  bestPlan?: boolean;
}

export interface T_PlanSelector {
  index: number;
  product?: T_Product;
  sku?: string;
  header: {
    title: string;
    subtitle: string;
  };
  description?: string;
  price: {
    name: string;
    fullPrice: string;
    nonStarterPrice: string;
    term: string;
    discount?: string;
    discountedPrice?: string;
  };
  features: string[];
  bundle: boolean;
  bestPlan?: boolean;
  imgSrc?: string;
  exploreImg?: string;
}

export enum InstallationType {
  SelfInstall,
  BYOD,
}

export interface ICartHeroCarousel {
  Carousel: ICartHeroCarouselItem[];
}

export interface ICartHeroCarouselItem {
  Image: string;
  MobileImage: string;
  Title: string;
  Description: string;
  Note: string;
  NoteColorDark: string;
  FullImageOnMobile: string;
}

export interface CheckoutNavigationContext {
  details: any;
  broadband: any;
  tv: any;
  voice: any;
  review: any;
}

export type CheckoutNavigationContextType = keyof CheckoutNavigationContext;

export interface ICheckoutRoute {
  title: string;
  path: string;
  component: () => JSX.Element;
  exact: boolean;
  type: string;
}

export enum BroadbandDeliveryType {
  NONE = '',
  ADDING = 'add',
  CHANGE_REQUIRES_DELIVERY = 'changeNeedsDelivery',
  CHANGE_REQUIRES_RETURN = 'changeNeedsReturn',
}

// TODO: Update list of attributes
export interface T_CURATED_PRODUCT {
  sku: string;
  name: string;
  description: string;
}

export interface T_CURATED_BUNDLE {
  dashSeparatedListOfSKUs: string;
  mainImageUrl: string;
  name: string;
  description: string;
  additionalInformation: string;
  price: string;
  secondaryButtonLabel: string;
  secondaryButtonURL: string;
  category: 'TV' | 'BROADBAND' | 'BROADBAND_TV';
  product: { product: string; icon: string; cover: string; sku: string }[];
}

export interface T_CURATED_QUIZ_ITEM {
  id: string;
  title: string;
  tags?: string[];
  imageURL?: string;
}

export interface T_CURATED_PRODUCT_POSTER {
  id: string;
  imageURL: string;
}

export interface T_ANALYTICS_QUIZ_ITEM {
  quiz_step_header: string;
  quiz_interaction_type: string;
  quiz_interaction_details: string;
  quiz_selections: string;
  quiz_progress_status: string;
  quiz_recommended_package: string;
  quiz_recommended_products: string;
}

export interface T_CustomerPrefs {
  accountNumber: string;
  emails: {
    broadbandOffers: boolean;
    frequency: string;
    otherContent: boolean;
    otherOffers: boolean;
    personalRecommendation: boolean;
    rewardsCompetition: boolean;
    snooze: boolean;
    snoozeCounter: number;
    snoozeDate: string | null;
    tvOffers: boolean;
    unsubscribeAll: boolean;
  };
  directEmails: {
    broadbandOffers: boolean;
    otherOffers: boolean;
    tvOffers: boolean;
    unsubscribeAll: boolean;
    rewardsCompetition?: boolean;
  };
  sms: { ppv: boolean; unsubscribeAll: boolean };
  outboundCalls: {
    broadbandOffers: boolean;
    otherOffers: boolean;
    tvOffers: boolean;
    unsubscribeAll: boolean;
    rewardsCompetition?: boolean;
  };
  door2Door: {
    unsubscribeAll: boolean;
  };
}

export interface useTotalResponse {
  monthlyCharge: number;
  monthlyPriceWithoutOffer: number;
  monthlyPriceWithOffer: number;
  priceYouPayToday: number;
  oneOff: number;
  ot_total_data:number;
}

export interface IceBreadcrumb {
  title: string;
  clickable: boolean;
  href: string;
}

export interface BreadcrumbRoute {
  path: string;
  title: string;
  component: any;
  exact: boolean;
}

export interface IPopUpContentConfig {
  id: string;
  sku: string;
  title: string;
  bodyCopy: string;
  link: string;
}

export interface ISkyMarkettingImage {
  id: string;
  name: string;
  url: string;
}

export enum BoxUpgradeStatus {
  'IN_CART',
  // process new box
  'PROCESSING',
  // upgrading before placing order
  'UPGRADING',
  // process upgrading
  'PROCESSINGUPGRADING',
}

export interface RecommendedBundle {
  ['BEST_VALUE']: T_CURATED_BUNDLE | null;
  ['RECOMMENDED']: T_CURATED_BUNDLE | null;
  ['UP_SELL']: T_CURATED_BUNDLE | null;
}

export interface RecommendedStructure {
  ['BEST_VALUE']: string | null;
  ['RECOMMENDED']: string | null;
  ['UP_SELL']: string | null;
}

export interface RecommendedArrayStructure {
  ['BEST_VALUE']: string[];
  ['RECOMMENDED']: string[];
  ['UP_SELL']: string[];
}

export interface T_OSDSendMsg {
  houseNumber: string;
  serialNumber: string;
  itemNumber?: string;
  osdId?: string;
}

export interface T_ROI_PAGE {
  data: object | null;
}

export interface T_ROI_CUSTOMER {
  dataset: object | null;
}

export type BOXTYPE = 'SUBSCRIBED' | 'NEW' | 'UPGRADE' | 'PENDING';

export interface Box {
  id?: string;
  occurence?: T_Occurrence;
  boxType: BOXTYPE;
  name: string;
  products: T_Product[];
  serviceCode?: string;
  removedProducts?: T_Product[];
  upgradeFrom?: T_Occurrence;
  primary?: boolean;
}

export type YesNoValueType = 'YES' | 'NO';

export type SkyCustomerYesNoType = {
  YES: 'YES';
  NO: 'NO';
};

export const SkyCustomerYesNoOption: SkyCustomerYesNoType = {
  YES: 'YES',
  NO: 'NO',
};

export interface SelfInstallDeliveryPayload {
  line1: string;
  line2: string;
  line3: string;
  line4: string;
  tui: string;
  deliveryInfo: {
    contactName: string;
    contactNumber: string;
    contactEmail: string;
    notes: string;
  };
}
export interface T_FaultSwapReplacementResponse {
  faultReplacementStatus: 'F' | 'S';
  faultErrorDesc: string;
  orderNumber: string;
}

export interface abandonedCartResponse {
  success: boolean;
}

import React, { SetStateAction, Dispatch } from 'react';
import { Modal, Button, ValidatedTextInput, closex, BlockLoader } from '../../';
import {
  capitalize,
  getProductImageUrl,
  MyAccountSWR,
  T_Occurrence,
  T_Product,
  unformattedTelPhoneNumber,
  useAddUpdateBox,
  useboxesStore,
  useToastContainer,
  validateBoxNickname,
} from '@sky-tv-group/shared';
import { useField, useForm } from 'react-jeff';
import { useState } from 'react';

interface IManageNicknameModalProps {
  modalIsOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  device: T_Occurrence;
  pkg: T_Product;
  myAccountSWR: MyAccountSWR;
}

export const ManageNicknameModal = ({
  modalIsOpen,
  setIsOpen,
  device,
  pkg,
  myAccountSWR,
}: IManageNicknameModalProps) => {
  const { addToast, addSuccessToast } = useToastContainer();
  let { data: profile } = myAccountSWR.useProfile();
  const [isProcessing, setIsProcessing] = useState(false);
  const { updateBoxtoStore } = useAddUpdateBox();
  const { boxesFromStore } = useboxesStore(s => ({ boxesFromStore: s.boxes }));

  const nicknameField = useField({
    defaultValue: device.nickName ?? '',
    validations: [validateBoxNickname],
  });

  const form = useForm({
    fields: [nicknameField],
    onSubmit: () => {},
  });

  const updateNickname = async () => {
    await form.submit();

    if (!form.valid) return;

    setIsProcessing(true);

    try {
      const response = await myAccountSWR.updateBox({
        serialNumber: device.serialNumber,
        accountNumber: profile?.accountNumber!,
        nickName: nicknameField.value,
      });

      if (response.status !== 200) {
        throw 'There was a server error updating the nickname';
      }

      const box = boxesFromStore.find(b => b.occurence?.occurrenceNumber === device.occurrenceNumber);

      if (box) {
        updateBoxtoStore(
          box.id!,
          box?.name!,
          box.boxType,
          box.products,
          box.upgradeFrom,
          !!box.primary,
          box.occurence ? { ...box.occurence, nickName: nicknameField.value } : undefined
        );
      }

      addSuccessToast({
        title: 'Box name updated',
        message: `The box with the serial number ${device.serialNumber} has successfully had it's nickname updated to ${nicknameField.value}`,
      });
      setIsOpen(false);
      setIsProcessing(false);
    } catch (e) {
      addToast({
        message:
          `Sorry, there was a problem updating this box. Please try again. If the problem persists please contact the Sky team on <a href={tel:${unformattedTelPhoneNumber}>${unformattedTelPhoneNumber}</a>`,
        type: 'error',
        title: 'Alert',
      });
      setIsProcessing(false);
    }
  };

  return modalIsOpen ? (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      customWidthClass="lg:min-w-64 rounded-md border border-gray-dark md:min-w-92">
      <div className="w-full flex p-5 pb-0">
        <button
          onClick={() => setIsOpen(false)}
          className="ml-auto w-12 h-12 text-white rounded-full bg-gray-darkest transform hover:bg-black hover:rotate-180 transition duration-500 ease-in-out">
          {closex}
        </button>
      </div>
      <div className="p-13 py-5 flex flex-col items-center">
        <img
          className="w-full max-w-xs lg:w-56 h-full block object-contain object-center mb-3"
          alt={pkg.name}
          src={getProductImageUrl(pkg)}
        />
        <span className="text-sm font-skyBook text-gray-darker">
          {device.occurrenceType === 'SKYBOX' ? 'Smartcard No.' : 'Serial No. '}
          {device.serialNumber}
        </span>

        <div className="mt-10 mb-8 text-left flex flex-col w-full">
          <span className="text-base font-bold text-gray-darker mb-1">Box Nickname</span>
          <ValidatedTextInput
            fieldValidation={nicknameField}
            formValidation={form}
            id="account-details-your-details-first-name"
            className="w-full rounded-md h-12"
            autoFocus
            type="text"
            onChange={str => {
              nicknameField.props.onChange(capitalize(str));
            }}
            placeholder="Ex.Living Room"
          />
          {isProcessing ? (
            <Button variant="primary" type="button" className="mt-8 min-w-48 mx-auto" colorMode="pure" disabled={true}>
              <BlockLoader style={{ height: '2rem', width: '4rem' }} />
            </Button>
          ) : (
            <Button
              variant="primary"
              type="button"
              onClick={updateNickname}
              className="mt-8 min-w-48 mx-auto"
              colorMode="pure"
              disabled={false}>
              Set Nickname
            </Button>
          )}
        </div>
      </div>
    </Modal>
  ) : (
    <></>
  );
};

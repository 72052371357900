import React from 'react';
import {
  categoryIds,
  formatMoney,
  T_OrderProduct,
  includedProductServiceCodes,
  useCoupon,
  productSkuIds,
  T_Product,
} from '@sky-tv-group/shared';
import { Bin } from './RunningTotalIcons';
import cx from 'classnames';
import { useState } from 'react';

interface RunningTotalProductProps {
  product: T_OrderProduct;
  removable?: boolean;
  included?: boolean;
  subscribed?: boolean;
  crossout?: boolean;
  oneOff?: boolean;
  removeHandlerBegin?: (value: boolean) => void;
  removeHandlerEnd?: (value: boolean) => void;
  removeProduct: (product: T_Product) => void;
  oneoffBoxType?: string;
  showoneOff?: boolean;
  showNew?: boolean;
  bundlePackages?:{}
}

const RunningTotalProduct = React.memo(
  ({
    product,
    removable = true,
    included = false,
    crossout = false,
    subscribed,
    removeHandlerBegin,
    removeHandlerEnd,
    oneOff = false,
    removeProduct,
    oneoffBoxType,
    showoneOff = false,
    showNew = false,
    bundlePackages,
  }: RunningTotalProductProps) => {
    const isMultiroomBoxOrBoxOneOffFee =
      product.categoryId === categoryIds.multiroom || product.product.sku === productSkuIds.arrowUpfrontBoxFee.primary;

    const isIncluded = includedProductServiceCodes.includes(product.product.sku);
    const isSkyAccess = product.product.sku === productSkuIds.access.primary;

    removable = subscribed ? false : removable;

    /** Show striked out original price.  */
    let hasOffer = false;

    if (product.product.categoryId === categoryIds.broadband) {
      hasOffer = (subscribed ? product.product.price0 : product.product.price1) !== product.price;
    } else {
      hasOffer = product.price !== product.product.priceIncTax;
    }

    // only BB plans has price1, which is the price with starter offer
    // For BB products if subscribed, show price0 (original price) else price1
    const normalPrice = hasOffer ? (
      formatMoney(
        ((subscribed ? product.product.price0 : product.product.price1) ?? product.product.priceIncTax) *
          product.quantity
      )
    ) : (
      <span></span>
    );
    const normalPriceClasses = cx('sky-text-red line-through pr-1', {
      'w-auto': hasOffer,
      'w-full': !hasOffer,
      'sky-h6-reg': showNew,
      'sky-h7-reg': !showNew,
    });
    const { hasDynamicCoupon } = useCoupon();
    const isWifiBooster = product.product.sku === productSkuIds.meshDevice.primary;

    const [removeDisabled, setRemoveDisabled] = useState(false);

    async function removeHandler() {
      setRemoveDisabled(true);
      if (removeHandlerBegin) removeHandlerBegin(true);
      await removeProduct(product.product);
      if (removeHandlerEnd) removeHandlerEnd(false);
      setRemoveDisabled(false);
    }
    async function removeBundleHandler() { 
      setRemoveDisabled(true);
      if (removeHandlerBegin) removeHandlerBegin(true);
      await removeProduct(product.product);
      if (removeHandlerEnd) removeHandlerEnd(false);
      setRemoveDisabled(false);
    }

    let hasRecording =
      (product.product.sku.includes(productSkuIds.arrowBox.primary) &&
        product.product.sku.includes(productSkuIds.arrowBoxRecording.primary)) ||
      product.product.sku.includes(productSkuIds.upfrontRecording.primary);
    let bundleItems=['202','242','238'];
    let bundleAdded=bundlePackages? true: false ;
    removable=product.product.sku=== productSkuIds.skyBestOfferBundle.primary && !subscribed? true : removable;

    return (
      <div className=" text-navy pb-2 ">
        <p
          className={`flex justify-between ${showNew ? 'sky-h6-reg items-start' : 'sky-h7-reg text-13px items-center'} ${
            crossout ? 'line-through text-red' : ''
          }`}
          data-testid={`running-total-product-${product.productId}`}>
          <span className={`flex items-center ${window.location.pathname==='/build-my-plan/checkout' ? 'sky-h6-reg':""}`}>
            
              <span
                className="icon-wrapper mr-3 sky-text-pink"
                style={{ width: '15px', height: '15px', display: 'inline-block', cursor: 'pointer' }}>
                  {removable && !removeDisabled && !(bundleAdded && bundleItems.includes(product.product.sku))  &&  (
                <span onClick={product.product.sku===productSkuIds.skyBestOfferBundle.primary ? removeBundleHandler :removeHandler}>
                  <Bin />
                </span>
                 )}
              </span>
           
            {isMultiroomBoxOrBoxOneOffFee && product.quantity > 1
              ? product.product.name + ' ' + product.quantity + 'x'
              : product.product.name}
            {hasRecording && <small className="text-gray-darker px-2">(My Sky) </small>}
            {isIncluded ? ' (Use included)' : subscribed ? ' (Subscribed)' : ''}
            {/* {oneoffBoxType === 'add' ? ' (add)' : oneoffBoxType === 'upgrade' ? ' (upgrade)' : ''} */}
          </span>

          <span className={(window.location.pathname==='/build-my-plan/checkout') ? 'sky-h6-reg':''}>
            {(product.product.categoryId !== categoryIds.hindiChannels || hasDynamicCoupon()) && !isWifiBooster && (
              <span className={normalPriceClasses}>{normalPrice}</span>
            )}
            {formatMoney(isMultiroomBoxOrBoxOneOffFee ? product.price * product.quantity : product.price)}

            {oneOff && !showoneOff ? (
              ''
            ) : oneOff && showoneOff ? (
              <>
                /<br className="md:hidden" />
                <span className="flex justify-end md:inline">one-off</span>
              </>
            ) : (
              '/mth'
            )}
          </span>
        </p>
        <div className="flex justify-end text-gray-darker">
          {product.product.sku.includes(productSkuIds.arrowBox.primary) && product.product.price3 && (
            <p>(Box ${product.product.price3} one-off)</p>
          )}
        </div>

        {(included || isSkyAccess) && (
          <p className="sky-sub pt-1 text-gray-darker ">Included, on a 12 month contract</p>
        )}
        {isSkyAccess && <p className="sky-sub py-1 text-gray-darker ">Includes one My Sky recording capability</p>}
      </div>
    );
  }
);

export { RunningTotalProduct };

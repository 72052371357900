import { Campaign, Product } from '@sky-tv-group/graphql';
import { parseISO } from 'date-fns';
import _clamp from 'lodash.clamp';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useMemo, useState } from 'react';
import {
  broadbandCategories,
  categoryIds,
  voiceCategories,
  productSkuIds,
  includedProductServiceCodes,
  productsHiddenInBillsAndPayments,
} from '../config';
import { MyAccountV2SWR } from '../entry';
import { getActiveCampaigns } from '../swr/helper';
import { MyAccountSWR } from '../swr/myaccount';
import { ServiceStatus, T_MOP } from '../types';
import { formatMoneyWithoutSignOrComma } from './format';
import { convertGraphqlCampaigns } from './graphqlHelpers';
import { parseQuery } from './parseQuery';

export interface BillingSection {
  title: string;
  total: number;
  totalTitle: string;
  items: Product[];
}

const MIN_TO_PAY = 5;
const MAX_TO_PAY = 2000;

let getDiscrepancies = (monthlyCharge: number, totalPrices: number, totalDiscount: number) => {
  monthlyCharge = Math.round(monthlyCharge * 100);
  totalPrices = Math.round(totalPrices * 100);
  totalDiscount = Math.round(totalDiscount * 100);

  let discrepancies = (monthlyCharge - (totalPrices + totalDiscount)) / 100;
  return discrepancies;
};

let getCardType = (mops: T_MOP[] | undefined) => {
  let activeMop = mops?.find(m => m.mopasgnd === 'Y');
  if (!activeMop) {
    return undefined;
  }
  return activeMop.mthdofpaycode === '3' ? 'debit card' : 'credit card';
};

let isMultiroomChargeFree = (p: Product) => {
  return p.sku === productSkuIds.multiroom.primary && p.occurrenceNumber === 1;
};

export const useUpcoming = (
  myAccountSWR: MyAccountSWR,
  myAccountV2SWR: MyAccountV2SWR,
  search: string = '',
  redirectToPayUrl?: (p: any) => void,
  isVTVCustomer: boolean = true
) => {
  let queries = parseQuery(search);
  let userInfoGQL = myAccountV2SWR.useUserInformationGQL();
  let campaigns = convertGraphqlCampaigns(userInfoGQL?.data?.billing?.campaigns as Campaign[]);
  let { data: mops, isValidating: mopsLoading } = myAccountSWR.useMop();
  let { data: products } = myAccountSWR.useProducts();

  let [amountToPay, setAmountToPay] = useState('10');
  let [error, setError] = useState(false);
  let [modalIsOpen, setIsOpen] = useState(queries?.pay === 'true');

  let cardType = getCardType(mops);
  let amountDue = userInfoGQL?.data?.billing?.currentTotalAmountDueCents ?? 0;
  let category = userInfoGQL?.data?.customer?.category?.toUpperCase()?.includes('COMMERCIAL');
  let monthlyChargeData = userInfoGQL?.data?.billing?.monthlyCharge ?? 0;
  let paperCharge: number = 1.5;
  let monthlyCharge = category
    ? monthlyChargeData > 0
      ? monthlyChargeData - paperCharge
      : monthlyChargeData
    : monthlyChargeData;

  let paperlessBillingProduct = products?.find((p: { sku: string }) => p.sku === productSkuIds.paperBilling.primary);

  let canPay = !!amountToPay && !error;
  let activeCampaigns = getActiveCampaigns(campaigns ?? []);
  let bought = useMemo(() => {
    if (userInfoGQL?.data?.billing) {
      let groupedProducts = userInfoGQL?.data?.billing?.products
        .filter(
          p =>
            p.quantity > 0 &&
            p.categoryId !== categoryIds.additionalCharges &&
            (p.serviceStatus === ServiceStatus.Active || p.serviceStatus === ServiceStatus.PendingDisconnect) &&
            !isMultiroomChargeFree(p)
        )
        .reduce(
          // grouping products by id, increasing quantity when a similar product is found in acc array
          (acc, value) => {
            const mutableValue = cloneDeep(value); // need to clone as GraphQL types are declared as readonly
            const idx = acc.findIndex(p => p.productId === mutableValue.productId);
            if (idx !== -1) {
              acc[idx].quantity = acc[idx].quantity + 1;
              acc[idx].originalPrice = acc[idx].originalPrice + mutableValue.originalPrice;
              acc[idx].billedPrice =
                (acc[idx].campaignPrice ?? acc[idx].billedPrice ?? 0) +
                (mutableValue.campaignPrice ?? mutableValue.billedPrice ?? 0);
            } else {
              acc.push(mutableValue);
            }
            return acc;
          },
          [] as Product[]
        )
        .reduce(
          (acc, value) => {
            let mutableValue = cloneDeep(value);
            let idx = 0;

            if (broadbandCategories.includes(value.categoryId) || voiceCategories.includes(value.categoryId)) {
              idx = 1;
              if (broadbandCategories.includes(value.categoryId) && voiceCategories.includes(value.categoryId)) {
                let extraTitle = ' and Home Phone';
                if (!acc[idx].title.includes(extraTitle)) {
                  acc[idx].title += extraTitle;
                  acc[idx].totalTitle += extraTitle;
                }
              }

              if (!broadbandCategories.includes(value.categoryId) && voiceCategories.includes(value.categoryId)) {
                let extraTitle = 'Home Phone';
                acc[idx].title = extraTitle;
                acc[idx].totalTitle = extraTitle;
              }
            } else if (isVTVCustomer) {
              mutableValue.billedPrice = value.campaignPrice ?? value.billedPrice;
            }

            if (productsHiddenInBillsAndPayments.includes(value.sku)) {
              return acc;
            }

            acc[idx].items.push(mutableValue);
            acc[idx].total += mutableValue.campaignPrice ?? mutableValue.billedPrice ?? 0;

            // if it's included, then should not count its saving.
            if (!includedProductServiceCodes.includes(mutableValue.sku)) {
              acc[idx].savings += mutableValue?.sku?.includes(productSkuIds.skyBestOfferBundle.primary)
                ?
                -(mutableValue?.billedPrice!+9.99) //bundle offer package included  it should detect overall bundle package product
                :
                mutableValue.originalPrice - (mutableValue.campaignPrice ?? mutableValue.billedPrice ?? 0);
            }

            return acc;
          },
          [
            {
              title: 'TV Subscription',
              total: 0,
              savings: 0,
              items: [] as Product[],
              totalTitle: 'Total TV',
            },
            {
              title: 'Broadband',
              total: 0,
              savings: 0,
              items: [] as Product[],
              totalTitle: 'Total Broadband',
            },
          ]
        );

      return groupedProducts;
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoGQL?.data?.billing]);

  let totalPriceBilled = bought.reduce((prev, cur) => {
    return prev + (cur.total ?? 0);
  }, 0);
  let totalSaved = bought.reduce((prev, cur) => {
    return prev + (cur.savings ?? 0);
  }, 0);

  let adminCharges =
    userInfoGQL?.data?.billing?.products.filter(
      p => p.quantity > 0 && p.categoryId === categoryIds.additionalCharges
    ) ?? [];
  let adminTotal = adminCharges.reduce((prev, curr) => prev + (curr.billedPrice ?? 0), 0);

  // discount is already counted in priceBilled. so pass 0 here.
  let discrepancies = getDiscrepancies(monthlyCharge, totalPriceBilled + adminTotal, 0);

  useEffect(() => {
    let initAmountToPay;
    if (amountDue > 0) {
      initAmountToPay = _clamp(amountDue, MIN_TO_PAY, MAX_TO_PAY);
    } else {
      initAmountToPay = '';
    }
    setAmountToPay(initAmountToPay.toString());
  }, [amountDue]);

  useEffect(() => {
    if (amountToPay) {
      let number = parseFloat(amountToPay);
      setError(!(number >= MIN_TO_PAY && number <= MAX_TO_PAY && /^\d+\.*\d{0,2}$/.test(amountToPay)));
    }
  }, [amountToPay]);

  let onPayClick = async () => {
    redirectToPayUrl?.({
      successURL: `${window.location.origin}/payment?callback=success&type=oneoff&amount=${amountToPay}`,
      failureURL: `${window.location.origin}/payment?callback=failure&type=oneoff&amount=${amountToPay}`,
      amount: formatMoneyWithoutSignOrComma(parseFloat(amountToPay)),
    });
  };

  let dueDate = userInfoGQL?.data?.billing?.statementDueDate
    ? parseISO(userInfoGQL?.data?.billing?.statementDueDate)
    : undefined;

  let loading = userInfoGQL?.loading || mopsLoading;

  return {
    loading,
    discrepancies,
    bought,
    activeCampaigns,
    adminCharges,
    modalIsOpen,
    setIsOpen,
    cardType,
    amountDue,
    monthlyCharge,
    totalSaved,
    canPay,
    amountToPay,
    setAmountToPay,
    onPayClick,
    dueDate,
    error,
    paperlessBillingProduct,
  };
};
